import { FaStar, FaStarHalfAlt, FaRegStar } from 'react-icons/fa';


export const contactReviews = [
    {
      rating: 4.5,
      stars: [<FaStar />, <FaStar />, <FaStar />, <FaStar />, <FaStarHalfAlt />],
      text: "We could trust them and relied on their recommendations.",
      reviewer: "Co-Founder, Explore Talent",
      verified: "Verified Review",
    },
    {
      rating: 4.0,
      stars: [<FaStar />, <FaStar />, <FaStar />, <FaStar />, <FaRegStar />],
      text: "We will definitely work with Aman if we need any other javascript work in the future.",
      reviewer: "General manager, SurveyBite",
      verified: "Verified Review",
    },
  ];

//   "We could trust their expertise in JavaScript technologies, especially their proficiency with the MERN stack, and relied on their recommendations."

