import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

// Updated Example Data Arrays
const data = [
  {
    category: 'Services',
    items: [
      { name: 'Web Development', url: '#' },
      { name: 'Mobile App Development', url: '#' },
      { name: 'Desktop App Development', url: '#' },
      { name: 'UX/UI Design', url: '#' }
    ]
  },
  {
    category: 'Technologies',
    items: [
      { name: 'React JS', url: '#' },
      { name: 'Next JS', url: '#' },
      { name: 'Node JS', url: '#' },
      { name: '.NET', url: '#' },
      { name: 'Typescript', url: '#' },
      { name: 'React Native', url: '#' },
      { name: 'AWS', url: '#' }
    ]
  },
  {
    category: 'Industries',
    items: [
      { name: 'Fintech', url: '#' },
      { name: 'Banking', url: '#' },
      { name: 'Accounting', url: '#' }
    ]
  },
  {
    category: 'Contact',
    link: '/contact' // Adding a link for Contact
  }
];

const Drawer = ({ isOpen, onClose }) => {
  const [activeDropdown, setActiveDropdown] = useState(null); // Manages active dropdown

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden'; // Disable scroll when drawer is open
    } else {
      document.body.style.overflow = ''; // Enable scroll when drawer is closed
    }
  }, [isOpen]);

  // Toggle dropdown functionality
  const toggleDropdown = (id) => {
    setActiveDropdown(activeDropdown === id ? null : id);
  };

  return (
    <div
      className={`fixed top-0 left-0 w-full h-full bg-black bg-opacity-80 z-50 transform ${
        isOpen ? 'translate-x-0' : '-translate-x-full'
      } transition-transform duration-500 ease-in-out`}
      style={{ backdropFilter: 'blur(5px)' }}
    >
      <div className="w-full h-full flex flex-col p-4 relative">
        {/* Close Button (Cross at the top right) */}
        <button
          className="absolute top-4 right-4 text-white text-3xl"
          onClick={onClose}
        >
          &times;
        </button>

        <ul className="w-full mt-12">
          {data.map((section, idx) => (
            <li
              key={idx}
              className={`mb-4 w-full text-center transition-transform duration-300 ${
                activeDropdown === idx ? '' : 'translate-y-6'
              }`}
            >
              {section.link ? (
                // Handle categories with a link
                <Link
                  to={section.link}
                  className="w-full text-white text-2xl py-3 hover:bg-themeTeal hover:text-ThemeBlack"
                  onClick={onClose}
                >
                  {section.category}
                </Link>
              ) : (
                <>
                  <button
                    className="w-full text-white text-2xl py-3 hover:bg-themeTeal hover:text-ThemeBlack"
                    onClick={() => toggleDropdown(idx)}
                  >
                    {section.category}
                  </button>

                  {/* Dropdown Content */}
                  <div
                    className={`overflow-hidden transition-max-height duration-300 ease-in-out ${
                      activeDropdown === idx ? 'max-h-40' : 'max-h-0'
                    }`}
                    style={{ maxHeight: activeDropdown === idx ? `${section.items.length * 80}px` : '0' }}
                  >
                    {section.items.length > 0 ? (
                      <ul className="bg-themeGray p-4">
                        {section.items.map((item, itemIdx) => (
                          <li key={itemIdx} className="py-2 text-gray-300">
                            <a href={item.url} className="text-gray-300">
                              {item.name}
                            </a>
                          </li>
                        ))}
                      </ul>
                    ) : (
                      <div className="bg-themeGray p-4 text-gray-300">
                        <p>No items available</p>
                      </div>
                    )}
                  </div>
                </>
              )}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Drawer;
