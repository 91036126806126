import React from "react";
import { Dev1, Dev2, Dev1Bg, newProfile,Dev3,Dev4} from "../../assets";

const ImageContainer = () => {
  const img = "https://www.creative-tim.com/learning-lab/tailwind-starter-kit/img/team-4-470x470.png";

  // Common CSS for the image container and images
  const imageContainerStyles = "w-[4.2rem] h-[4.2rem] md:w-[5rem] md:h-[5rem] overflow-hidden relative rounded-full bg-gray-500";
  const imageStyles = "absolute top-0 left-0 w-full h-full object-cover rounded-full";

  const images = [Dev1, Dev2, Dev1Bg, newProfile];

  return (
    <div className="flex gap-4 md:gap-6 items-center w-full">
      {/* <span className="text-Themewhite text-[4rem]">'</span> */}
      <section className="flex h-full mt-5 w-full justify-between">
       <div className={imageContainerStyles}>
          <img src={newProfile} alt="Dev1" className='absolute top-2 -left-1 w-full h-full object-cover rounded-full' />
        </div>
       <div className={imageContainerStyles}>
          <img src={Dev2} alt="Dev1" className={imageStyles} />
        </div>
       <div className={imageContainerStyles}>
          <img src={Dev3} alt="Dev1" className='absolute top-2 left-1 w-full h-full object-cover rounded-full' />
        </div>
        <div className={imageContainerStyles}>
  <img 
    src={Dev4} 
    alt="Dev1" 
    className="absolute top-[-30%] left-[5%] w-[140%] h-[140%] object-cover rounded-full" 
  />
</div>

     
      </section>
      {/* <span className="text-Themewhite text-[4rem]">'</span> */}
    </div>
  );
};

export default ImageContainer;

