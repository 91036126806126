// components/Modal.js
import React from 'react';

const SuccessModal = ({ message, onClose }) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-75 z-50">
      <div className="bg-white p-6 rounded-lg shadow-lg max-w-sm w-full">
        <h2 className="text-2xl font-semibold text-center">Great!</h2>
        <p className="mt-4 text-center">{message}</p>
        <div className="mt-6 text-center">
          <button
            className="px-6 py-2 bg-themeTeal text-white rounded-lg"
            onClick={onClose}
          >
            OK
          </button>
        </div>
      </div>
    </div>
  );
};

export default SuccessModal;
