import React from "react";
import { DaysInfo } from "../reusableComponents/DaysInfo";

const OnboadingContainer = () => {
  return (
    <>
      <main class="lg:flex justify-between">
          <section class="mb-14 lg:mb-0 lg:max-w-[40%]">
          <p class="text-themeTeal text-lg mb-6 uppercase font-bold">
            <span className="text-2xl ">[</span> why work with {" "}
             <span className="Logo-font-family">
              32signals 
              </span>
              <span className="text-2xl">]</span>
          </p>      
            <h1 className="text-3xl md:text-4xl font-semibold leading-10 mb-6">
            Expect the first pull request in 10 days from today.
            </h1>
            <p class=" text-lg mb-6">
            The goal is to get you the best team of engineers in the shortest time and start delivering real business value.
            </p>
            <button
              type="button"
              class="bg-teal-400 text-ThemeBlack uppercase hover:bg-teal-500 focus:ring-4 font-medium w-full sm:w-80 py-2 "
            >
              Get a proposal
            </button>
          </section>
          <section className="lg:max-w-[50%]">
      <DaysInfo day={1}
       title={" Share the details of your project, including your requirements and any constraints"}
       para={"Typically, this involves exchanging documents and a video call. If needed, we’re happy to sign an NDA to ensure confidentiality.       "}
       isArrow={true}
       />
       <DaysInfo day={4}
       title={" Tell us about your project, needs and constraints"}
       para={"This usually happens in a form of docs exchange and a video call. We sign NDA if you require one."}
       isArrow={true}

       />
      <DaysInfo day={7}
       title={" Tell us about your project, needs and constraints"}
       para={"This usually happens in a form of docs exchange and a video call. We sign NDA if you require one."}
       isArrow={true}

       />
      <DaysInfo day={10}
       title={" Tell us about your project, needs and constraints"}
       para={"This usually happens in a form of docs exchange and a video call. We sign NDA if you require one."}
       isArrow={false}

       />

</section>
      </main>
    </>
  );
};

export default OnboadingContainer;


