import React, { useState } from 'react';
import Layout from '../components/layouts/layout';
import { contactReviews } from '../utils/reviews';
import emailjs from 'emailjs-com'; // Import EmailJS
import { reusableClasses } from '../utils/reusableClasses.';
import SuccessModal from '../components/modals/SuccessModal';
import { useNavigate } from 'react-router-dom';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
    purpose: '',
    consent: false,
  });
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [showModal, setShowModal] = useState(false); // State for modal visibility

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    // Send email using EmailJS
    const templateParams = {
      name: formData.name,
      email: formData.email,
      phone: formData.phone || '',  // Include phone only if provided
      message: formData.message,
      purpose: formData.purpose || '',  // Include purpose only if provided
    };
    

    emailjs
      .send(
        'service_urusx0m',       // EmailJS Service ID
        'template_4hgs4ds',       // EmailJS Template ID
        templateParams,
        'Kd2HEfSGraGMZhNJi'            // EmailJS User ID
      )
      .then(
        (response) => {
          console.log('SUCCESS!', response.status, response.text);
          setIsSubmitted(true);
          setIsLoading(false);
          setShowModal(true);
        },
        (error) => {
          console.error('FAILED...', error);
          setIsLoading(false);
        }
      );
  };

  const handleModalClose = () => {
    setShowModal(false);
    navigate('/'); // Redirect to the home page when "OK" is clicked
  };


  return (
    <Layout>
      <div className={reusableClasses.container}>
        <div className={reusableClasses.formWrapper}>
          {/* Left Side - Form */}
          <div className={reusableClasses.formContainer}>
            <h2 className={reusableClasses.formTitle}>Tell us about your project</h2>
            <form className="space-y-4" onSubmit={handleSubmit}>
              <div>
                <label htmlFor="name" className={reusableClasses.label}>Name</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  className={reusableClasses.input}
                  required
                />
              </div>

              <div>
                <label htmlFor="email" className={reusableClasses.label}>Company Email</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  className={reusableClasses.input}
                  required
                />
              </div>

              <div>
                <label htmlFor="phone" className={reusableClasses.label}>Phone Number (Optional)</label>
                <input
                  type="tel"
                  id="phone"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  className={reusableClasses.input}
                />
              </div>

              <div>
                <label htmlFor="message" className={reusableClasses.label}>About my product (Optional)</label>
                <textarea
                  id="message"
                  name="message"
                  rows="4"
                  value={formData.message}
                  onChange={handleChange}
                  className={reusableClasses.textarea}
                  placeholder="e.g. tech stack, scope, project timeline"
                ></textarea>
              </div>

              <div>
                <p className="text-sm text-gray-600 mb-2">I want to:</p>
                <div className="space-y-2">
                  <label className={reusableClasses.radioLabel}>
                    <input
                      type="radio"
                      name="purpose"
                      value="scale"
                      checked={formData.purpose === 'scale'}
                      onChange={handleChange}
                      required
                    /> Scale my development team
                  </label>
                  <label className={reusableClasses.radioLabel}>
                    <input
                      type="radio"
                      name="purpose"
                      value="build"
                      checked={formData.purpose === 'build'}
                      onChange={handleChange}
                      required
                    /> Build a digital product from scratch
                  </label>
                  <label className={reusableClasses.radioLabel}>
                    <input
                      type="radio"
                      name="purpose"
                      value="modernize"
                      checked={formData.purpose === 'modernize'}
                      onChange={handleChange}
                      required
                    /> Modernize existing application
                  </label>
                  <label className={reusableClasses.radioLabel}>
                    <input
                      type="radio"
                      name="purpose"
                      value="other"
                      checked={formData.purpose === 'other'}
                      onChange={handleChange}
                      required
                    /> Something else
                  </label>
                </div>
              </div>

              <div>
                <label className={reusableClasses.consentLabel}>
                  <input
                    type="checkbox"
                    name="consent"
                    checked={formData.consent}
                    onChange={handleChange}
                    className={reusableClasses.checkbox}
                    required
                  />
                  I consent to the processing of my personal data for marketing purposes.
                </label>
              </div>

              <button
                type="submit"
                className={reusableClasses.button}
                disabled={isLoading}
              >
                {isLoading ? 'Submitting...' : 'Submit'}
              </button>
            </form>
            <p className={reusableClasses.rightText + ' mt-6'}>
              P.S. In case you hate forms: <br />
              <a href="mailto:aman@21signals.com" className={reusableClasses.link}>
                aman@21signals.com
              </a>
            </p>
          </div>

          {/* Divider */}
          <div className={reusableClasses.divider}></div>

          {/* Right Side - Reviews */}
          <div className={reusableClasses.rightSide}>
            <div className={reusableClasses.reviewsContainer}>
              <h3 className={reusableClasses.reviewsTitle}>32signals Reviews</h3>

              {contactReviews.map((review, index) => (
                <div key={index} className={reusableClasses.reviewBox}>
                  <div className={reusableClasses.reviewRating}>
                    <span className="text-ThemeBlack">{review.rating}</span>
                    {review.stars.map((star, i) => (
                      <span key={i} className="ml-1">{star}</span>
                    ))}
                  </div>
                  <p className={reusableClasses.reviewText}>{review.text}</p>
                  <p className={reusableClasses.reviewer}>{review.reviewer}</p>
                  <p className={reusableClasses.verified}>{review.verified}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
        {showModal && (
        <SuccessModal
          message="We will contact you within 24 hours."
          onClose={handleModalClose}
        />
      )}
      </div>
    </Layout>
  );
};

export default ContactForm;
