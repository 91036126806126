import { FiArrowUpRight } from 'react-icons/fi';

export default function HeadingWithCTA({ title, description }) {
  return (
    <div >
      <div className="container mx-auto px-2 py-8 md:py-8 transition-all duration-300 ease-in-out group-hover:shadow-lg ">
        <div className="grid md:grid-cols-2 gap-4 sm:gap-8">
          <div className="flex items-start"> {/* Align to start */}
            <h2 className="text-[2rem] text-ThemeBlack font-semibold leading-tight">
              {title}
            </h2>
          </div>
          <div className="flex flex-col justify-start">
            <div className="flex flex-col sm:flex-row items-start sm:items-start space-y-2 sm:space-y-0 sm:space-x-4">
              <p className="text-base text-themeGray">
                {description} {/* Fixed typo: 'discription' to 'description' */}
              </p>
              <button className="bg-black text-white px-2 sm:px-4 py-1 sm:py-4 flex items-center space-x-1 sm:space-x-2 flex-shrink-0 text-xs sm:text-sm whitespace-nowrap">
                <FiArrowUpRight size={16} className="sm:w-4 sm:h-4" />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full h-[.5px] bg-gray-400 transition-all duration-300 ease-in-out group-hover:h-0.5 group-hover:bg-themeGray group-hover:font-bold"></div>
    </div>
  );
}
