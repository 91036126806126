const reusableClasses = {
  container: "min-h-screen bg-gray-100 flex justify-center items-center py-20 px-4",
  formWrapper: "w-full max-w-5xl flex flex-col md:flex-row",
  formContainer: "md:w-full md:px-8 rounded-lg",
  formTitle: "text-2xl font-semibold mb-6",
  input: "w-full p-3 border border-gray-300 rounded-md",
  label: "block mb-2 text-sm font-medium text-gray-700",
  textarea: "w-full p-3 border border-gray-300 rounded-md",
  radioLabel: "block",
  consentLabel: "flex items-center",
  checkbox: "mr-2",
  button: "w-full bg-themeTeal text-white p-3 rounded-md hover:bg-themeTeal transition",
  divider: "w-1 bg-gray-300 mx-4",
  rightSide: "w-full md:w-1/2 mt-6 md:mt-0", // Reviews section position
  reviewsContainer: "bg-white p-6 shadow-md rounded-md text-gray-700 space-y-4",
  reviewsTitle: "text-2xl font-semibold text-gray-700 mb-4",
  reviewBox: "p-4 border rounded-md shadow-sm bg-gray-50",
  reviewRating: "flex items-center text-lg font-bold text-themeTeal",
  reviewText: "text-sm mt-4 text-ThemeBlack",
  reviewer: "text-xs text-gray-500 mt-2",
  verified: "text-[8px] mt-1 text-gray-500",
  rightText: "text-lg text-gray-700",
  link: "text-blue-500 underline",
};

module.exports = {
  reusableClasses
};
