import React from 'react';
import Header from './header';
import Footer from './Footer';

const Layout = ({ children }) => {
  return (
    <div className="flex flex-col min-h-screen bg-ThemeBlack ">
      {/* Header */}
      <header className="sticky top-0 z-30 bg-white shadow-lg">
   <Header />
</header>


      {/* Main Content */}
      <main >
        {children}
      </main>

      {/* Footer */}
      <footer className="bg-black p-4">
        <Footer/>
      </footer>
    </div>
  );
};

export default Layout;
