import React, { useState } from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import Drawer from '../reusableComponents/Drawer';
import { FaChevronDown } from "react-icons/fa";

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false); // State to control main menu

  // Navigation items with dropdowns for Services, Technologies, and Industries
  const navItems = [
    { name: 'Services', id: 'services', subItems: [
      { name: 'Web Development', id: 'web-development' },
      { name: 'Mobile App Development', id: 'mobile-app-development' },
      { name: 'UI/UX Design', id: 'ui-ux-design' },
    ]},
    { name: 'Technologies', id: 'technologies', subItems: [
      { name: 'React', id: 'react' },
      { name: 'Node.js', id: 'node-js' },
      { name: 'Shopify', id: 'shopify' },
      { name: 'AWS', id: 'aws' },
    ]},
    { name: 'Industries', id: 'industries', subItems: [
      { name: 'E-commerce', id: 'e-commerce' },
      { name: 'Healthcare', id: 'healthcare' },
      { name: 'Real Estate', id: 'real-estate' },
    ]},
    { name: 'Contact', link: '/contact' }, // Direct link to Contact page
  ];

  const closeDrawer = () => setMenuOpen(false); // Close menu when drawer is closed

  return (
    <>
      <nav className="border-gray-200 px-6 lg:px-10 py-3 bg-ThemeBlack text-Themewhite">
        <div className="flex flex-wrap justify-between items-center mx-auto max-w-screen-xl">
          {/* Logo */}
          <Link to='/'>
          <h1 className="text-xl lg:text-4xl font-bold Logo-font-family">32signals</h1>
          </Link>

          {/* Full Navigation for lg and above */}
          <div className="hidden lg:flex space-x-8">
            {navItems.map((link) => (
              <div key={link.id} className="relative group">
                {link.link ? (
                  // Direct link with React Router's Link component
                  <Link
                    to={link.link}
                    className="text-lg text-white hover:underline decoration-themeTeal transition-colors flex items-center"
                    onClick={closeDrawer} // Close drawer on link click
                  >
                    {link.name}
                  </Link>
                ) : (
                  <>
                    <a
                      href={`#${link.id}`}
                      className="text-lg text-white hover:underline decoration-themeTeal transition-colors flex items-center"
                    >
                      {link.name}
                      {link.subItems && <FaChevronDown className="ml-2 w-3 h-3" />} {/* Arrow for dropdown */}
                    </a>
                    {/* Dropdown */}
                    {link.subItems && (
                      <aside className=' absolute pt-2'>

                      <div className=" left-0  hidden group-hover:block group-hover:opacity-100 bg-gray-800 py-2 w-48 rounded-md shadow-lg transition-opacity duration-300 opacity-0 group-hover:pointer-events-auto">
                        {link.subItems.map((subLink) => (
                          <a
                            key={subLink.id}
                            href={`#${subLink.id}`}
                            className="block px-4 py-2 text-white hover:underline decoration-themeTeal"
                          >
                            {subLink.name}
                          </a>
                        ))}
                      </div>
                      </aside>

                    )}
                  </>
                )}
              </div>
            ))}
          </div>

          {/* Hamburger Menu for mobile (lg:hidden) */}
          <div className="flex items-center lg:hidden">
            <button
              type="button"
              className="inline-flex items-center p-3 ml-1 text-lg text-Themewhite rounded-sm bg-themeGray"
              onClick={() => setMenuOpen(!menuOpen)} // Toggle menu on click
            >
              <span className="sr-only">Open main menu</span>
              {menuOpen ? (
                <svg
                  className="w-6 h-6"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              ) : (
                <svg
                  className="w-6 h-6"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                    clipRule="evenodd"
                  />
                </svg>
              )}
            </button>
          </div>
        </div>
      </nav>

      {/* Fullscreen Drawer for mobile */}
      <Drawer isOpen={menuOpen} onClose={closeDrawer}>
        {/* <ul className="flex flex-col mt-10 font-medium text-center text-white">
          {navItems.map((link) => (
            <li key={link.id} className="mb-4">
              {link.link ? (
                // Direct link with React Router's Link component
                <Link
                  to={link.link}
                  className="block py-2 px-4 text-2xl hover:bg-gray-700"
                  onClick={closeDrawer} // Close drawer on link click
                >
                  {link.name}
                </Link>
              ) : (
                <a
                  href={`#${link.id}`}
                  className="block py-2 px-4 text-2xl hover:bg-gray-700"
                  onClick={closeDrawer}
                >
                  {link.name}
                </a>
              )}
            </li>
          ))}
        </ul> */}
      </Drawer>
    </>
  );
};

export default Header;
