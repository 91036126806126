import React from "react";

const SuggestionContainer = () => {
  return (
    <>
        <section class="flex-grow  flex flex-col justify-center items-center align-middle  text-Themewhite">
          <p class="text-themeTeal text-lg text-center uppercase mb-6">
            <span className="text-2xl text-Themewhite">[</span> why work with {" "}
             <span className="Logo-font-family">
              32signals 
              </span>
              <span className="text-2xl text-Themewhite">]</span>
          </p>              
          <h1 className="text-4xl md:text-[3rem] leading-10 text-center mb-6">
          "Effortless team expansion."
            </h1>
            <p className="mb-6 text-center">
  <span className="Logo-font-family">32signals</span> is a software agency that helps companies develop and upgrade software quickly, affordably, and without hiring risks.
</p>

          <button
            type="button"
            class="bg-teal-400 text-ThemeBlack uppercase hover:bg-teal-500 focus:ring-4 font-medium w-full sm:w-80 py-2 "
          >
            Get a proposal
          </button>                 
        </section>
    </>
  );
};

export default SuggestionContainer;
