import HeadingWithCTA from "../reusableComponents/HeadingWithCTA";

export default function WhatWeDo() {
    const contentData = [
        {
          title: 'React.JS and Next.JS Development',
          description: 'We specialize in building fast, scalable, and responsive web applications using React.JS. Our expertise with Next.JS ensures seamless integration of server-side rendering and static site generation, delivering high-performance apps that are optimized for both user experience and SEO.'
        },
      
        {
          title: 'Node.js Backend Solutions',
          description: 'Our team excels at developing reliable, scalable backend systems using Node.js. We design and implement robust APIs, manage complex data workflows, and create microservices that ensure your application remains responsive and efficient, even under heavy loads.'
        },
      
        {
          title: 'Fully Automated Testing',
          description: 'We incorporate automated testing throughout the development lifecycle to ensure high-quality, bug-free applications. From unit tests to comprehensive end-to-end testing, our automated processes help identify issues early, reducing time spent on manual testing and improving overall code stability.'
        },
      
        {
          title: 'App Modernization',
          description: 'We help businesses modernize legacy applications, ensuring they stay competitive and aligned with the latest technological standards. Whether it’s upgrading to modern frameworks, enhancing performance, or refining user interfaces, we transform outdated systems into high-performing, user-friendly solutions.'
        }
      ];
      

  return (
    <main>
      <h1 className="text-ThemeBlack text-center text-2xl md:text-3xl font-bold py-8">
        What we are doing?
      </h1>

      {contentData.map((item, index) => (
        <div className="my-7 w-full group">

        <HeadingWithCTA
          key={index}
          title={item.title}
          description={item.description}
          />
          </div>
      ))}
    </main>
  );
}
