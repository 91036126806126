export const DaysInfo =(
  {day,title,para,isArrow}
)=>{
    return(
        <section className="flex w-full justify-start gap-10 sm:gap-16">
        <aside className=" flex flex-col h-80 w-20 items-center justify-between">
  
<div className="w-16 h-16 md:h-20  flex items-center justify-center text-Themewhite rounded-sm bg-themeGray aspect-square">
<span className="text-base md:text-lg"> Day {day}</span>
</div>
{isArrow?
<div class="">
<div class="w-[3px] h-20 rounded-b-full mb-4 bg-gradient-to-t from-gray-500 to-transparent"></div>
</div>:null
}
        </aside>
<div>
<h1 className="text-lg md:text-2xl font-semibold leading-6 ">
  {title}
</h1>
<p className=" text-base md:text-lg mt-3 leading-6">
 
{para}
</p>
</div>
</section>

    )
}